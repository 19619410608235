import React from "react";

type InfoProps = {
  icon: React.ReactNode;
  name: React.ReactNode;
  label: string | null;
};

const InfoItem: React.FC<InfoProps> = ({ icon, name, label }) => {
  return (
    <div className="justify-start items-center gap-4 inline-flex">
      {icon}
      <div className="flex-col justify-start items-start gap-2 inline-flex">
        <div className="text-white text-sm font-normal font-['Poppins']">
          {name}
        </div>
        <div className="text-neutral-400 text-sm font-normal font-['Poppins']">
          {label}
        </div>
      </div>
    </div>
  );
};

export default InfoItem;
