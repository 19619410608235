import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { UserResponseData } from "../App.types";
import NameLabelComopnent from "./NameLabelComponent";
import { FaEnvelope, FaLink, FaPhone } from "react-icons/fa6";
import UserProfile from "../UserProfile/UserProfile";
import Loader from "../Components/Loader";


const Profile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState<UserResponseData | null>(null);
  const [base64Image, setBase64Image] = useState<string | null>(null)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `https://ultimus.store/api/v0/card/${userId}`
        );

        setUser(response.data.data);
        const avatar: string | null = response.data.data.avatar
        if (avatar != null && avatar !== '') {
          generateBase64(response.data.data.avatar)
            .catch(error => console.log(error))
            .then(base64 => {
              const imgStr = base64 as string | null
              if (imgStr != null && imgStr !== '') {
                setBase64Image(imgStr)
              }
            })
        }

      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };


    const generateBase64 = async (avatar: string) => {

      try {
        const response = await axios.get(avatar, {
          responseType: 'blob',
        });

        const blob = response.data;
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        //console.error('Error fetching the image:', error);
      }
    }

    fetchUser();
  }, [userId]);

  // TODO - Work on a better loader
  if (!user) {
    return <Loader />;
  }

  const fullName = `${user.first_name} ${user.last_name}`;
  const handleSaveInContact = async () => {
    const lowercaseCountry = user.address.country.toLowerCase();

    const splitBase64 = base64Image?.split(",") as string[] | null
    const imageAsString = splitBase64?.at(1)
    const imageFormat = splitBase64?.at(0)?.split(";")?.at(0)?.split("/").at(1)

    const vCardData = `
BEGIN:VCARD
VERSION:3.0
N;CHARSET=UTF-8:${user.last_name};${user.first_name};;;
FN;CHARSET=UTF-8:${fullName}
ORG;CHARSET=UTF-8:${user.company.name}
TITLE;CHARSET=UTF-8:${user.company.role}
item1.TEL;type=Mobile:${user.phone_number}
item1.X-ABLabel;CHARSET=UTF-8:Call Us
item2.EMAIL;type=Email:${user.email}
item2.X-ABLabel;CHARSET=UTF-8:Email
item3.ADR;CHARSET=UTF-8:;${user.address.apt}, ${user.address.street};;${user.address.town};${user.address.city};00233;${user.address.country}
item3.X-ABLabel;CHARSET=UTF-8:Address
item3.X-ABADR:${lowercaseCountry}
item4.URL:${user.company.url}
item4.X-ABLabel;CHARSET=UTF-8:${user.company.name}
PHOTO;ENCODING=b;TYPE=${imageFormat}:${imageAsString}
END:VCARD`;

    // item5.URL:https://www.nerdlabtech.com/
    // item5.X-ABLabel;CHARSET=UTF-8:NerdLab Technologies
    // PHOTO;ENCODING=b;TYPE=JPEG:/9j/4AAQSkZJRgABAQEAYABgAAD//gA8Q1JFQVRPUjogZ2QtanBl$
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fullName}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <UserProfile
      user={user}
      fullname={fullName}
      handleSaveInContact={handleSaveInContact}
    />

  );
};

export default Profile;
