import React from "react";
import { LoaderIcon } from "./Icons";

const Loader = () => {
  return (
    <div className="appear text-white w-full fixed z-10 inset-0 h-screen items-center block max-w-sm p-6 shadow-md dark:bg-gray-800/70">
      <div
        role="status"
        className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
      >
        <LoaderIcon />
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
