import React from "react";
import {
  CompanyIcon,
  EmailIcon,
  LocationIcon,
  LogoIcon,
  PhoneIcon,
  WebIcon,
} from "../Components/Icons";
import InfoItem from "./InfoItem";
import { UserResponseData } from "../App.types";

interface IUserProps {
  user: UserResponseData;
  fullname: string;
  handleSaveInContact: () => void;
}

const UserProfile: React.FC<IUserProps> = ({
  user,
  fullname,
  handleSaveInContact,
}: {
  user: UserResponseData;
  fullname: string;
  handleSaveInContact: () => void;
}) => {
  return (
    <div className="w-full h-screen relative bg-black px-5 appear">
      <div className="w-full h-44 left-0 top-[-37px] absolute bg-[#01693e]">
        <div className="w-full h-32 left-0 top-[37px] absolute bg-emerald-800" />
        <img
          className="w-full h-44 left-0 top-0 absolute"
          src={"background.png"}
          alt="bg-placeholder"
        />
      </div>

      <div className="w-full h-full pt-[77px] flex-col justify-between items-center gap-6 inline-flex">
        <div className="w-full flex-col justify-start items-start gap-7 flex">
          <div
            className="w-full h-40 rounded-2xl border bg-[#0d432d] border-neutral-500 flex items-center gap-5 px-5 py-6 relative !bg-cover !bg-no-repeat"
            style={{
              background: "url(profile-bg.png)",
            }}
          >
            <img
              className="w-28 h-28 rounded-full border border-neutral-500 object-cover"
              src={
                user.avatar ?? "avatar.jpeg"
              }
              alt="user"
            />

            <div className="w-3/5 flex flex-col justify-start items-start">
              <div className="text-white text-xl font-bold font-['Poppins']">
                <span className="break-normal">{user.first_name}</span>
                <br />
                <span className="break-normal">{user.last_name}</span>
              </div>
              <div className="text-white text-sm font-normal font-['Poppins'] pt-2">
                {user.company.role}
              </div>
            </div>
          </div>
          <div className="w-full relative">
            <button
              className="w-full h-[50px] text-white text-sm font-bold font-['Poppins'] rounded-lg border border-neutral-500"
              onClick={handleSaveInContact}
            >
              Save Contact
            </button>
          </div>
          <div className="flex-col justify-start items-start gap-6 flex">
            <div className="text-white text-base font-bold font-['Poppins']">
              Contact Details
            </div>
            <div className="flex-col justify-start items-start gap-4 flex">
              <InfoItem
                icon={<CompanyIcon />}
                name={
                  user.company.company_logo ?
                    <p className="w-full inline-flex items-center gap-2">
                      <img
                        className="max-h-[40px] max-w-[30%]"
                        src={user.company.company_logo ?? ""}
                        alt={user.company.name}
                      />
                      <div className="w-[1px] h-[10px] border-r border-[#6C6C6C]" />
                      <span>{user.company.name}</span>
                    </p> : user.company.name
                }
                label="Company"
              />
              <InfoItem
                icon={<PhoneIcon />}
                name={user.phone_number}
                label="Phone"
              />
              <InfoItem icon={<EmailIcon />} name={user.email} label="Email" />
              <InfoItem
                icon={<WebIcon />}
                name={user.company?.url}
                label="Website"
              />
              <InfoItem
                icon={<LocationIcon />}
                name={
                  <>
                    {user.address.apt}, {user.address.street} <br />{" "}
                    {user.address.town}, {user.address.city}
                  </>
                }
                label="Address"
              />
            </div>
          </div>
        </div>

        <div className="grid py-5">
          <div className="w-36 h-px border border-neutral-500 mb-2"></div>
          <div className="flex-col justify-center items-center gap-4 flex">
            <div className="text-neutral-400 text-[7px] font-normal font-['Poppins']">
              Powered by :{" "}
            </div>
            <div className="flex-col justify-start items-start gap-1.5 flex">
              <div className="justify-start items-end gap-px inline-flex">
                <LogoIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
