// import logo from './logo.svg';
import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Profile from "./profile/Profile";

const mobileThreshold = 640;
function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileThreshold);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < mobileThreshold);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // TODO - UPDATE! - path should use whether key is generated and passed in the card_url
  if (isMobile) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Nothing />} />
          <Route path="/:userId" element={<Profile />} />
        </Routes>
      </Router>
    );
  } else {
    return <Nothing />;
  }
}

function Nothing() {
  return <div></div>;
}

export default App;
